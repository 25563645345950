import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { MotionValue, motion } from "framer-motion"
import Stamp from "../assets/icons/logo_stamp.svg"
import { themeOptions } from "../theme/Styles"
import { navigate } from "gatsby"

const BenAppStamp: React.FC<{ scrollYProgress: MotionValue<number> }> = ({
  scrollYProgress,
}) => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    let timeoutId: NodeJS.Timeout

    const handleScroll = () => {
      setIsVisible(true)
      clearTimeout(timeoutId)
      timeoutId = setTimeout(() => {
        setIsVisible(false)
      }, 1000)
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
      clearTimeout(timeoutId)
    }
  }, [])

  return (
    <Figure className={`${isVisible ? "visible" : ""}`}>
      <svg id="progress" width="140" height="140" viewBox="0 0 140 140">
        <Stamp />
        <motion.circle
          cx="70"
          cy="70"
          r="66"
          pathLength="1"
          className="indicator"
          style={{ pathLength: scrollYProgress }}
        />
      </svg>
    </Figure>
  )
}

const Figure = styled.figure`
  position: fixed;
  z-index: 111;
  bottom: 2%;
  right: 2%;
  opacity: 0;
  transition: all 0.3s ease;
  transform: translateX(calc(100vw - 200px));

  &.visible,
  &:hover {
    opacity: 1;
    transform: none;
  }

  #progress {
    width: 7rem;

    circle {
      stroke-dashoffset: 0;
      stroke-width: 0.5rem;
      fill: none;
    }

    .indicator {
      stroke: #000000;
      transform: translateY(140px) rotate(-90deg);
    }

    ::-webkit-scrollbar {
      height: 12px;
      width: 5px;
      background: ${({ theme }) => theme.palette.text.border};
    }
  }
`

export default BenAppStamp
