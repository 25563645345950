import React, { useEffect, useState } from "react"
import styled from "styled-components"
import Logo from "../assets/icons/Logo.svg"
import { bp, themeOptions } from "../theme/Styles"
import { Container } from "./layouts/Container"
import Button from "./layouts/Button"
import useBreakpoint from "../utils/use-breakpoint"
import { PreventBodyScroll } from "../layouts"
import useScrollPosition from "../utils/use-scroll-position"
import FadeLink from "./layouts/FadeLink"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { navigate } from "gatsby"

interface NavigationItem {
  text: string
  path: string
  icon?: React.ReactElement
}

export const navigationItems: Array<NavigationItem> = [
  {
    text: "Einführung",
    path: "/#Intro",
  },
  {
    text: "So funktioniert’s",
    path: "/#So_funktioniert",
  },
  {
    text: "Ihre Vorteile",
    path: "/#Ihre_Vorteile",
  },
  {
    text: "Informiert bleiben",
    path: "/#Informiert_bleiben",
  },
]

const Navigation: React.FC = () => {
  const isMobile = useBreakpoint()
  const scrollPosition = useScrollPosition()

  const [isOpen, setOpen] = useState(false)
  const [isSticky, setIsSticky] = useState(false)
  const [activeSection, setActiveSection] = useState("/#Intro")

  useEffect(() => {
    if (scrollPosition > 0) setIsSticky(true)
    else setIsSticky(false)
  }, [scrollPosition])

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll("section")
      let currentSection: string | null = "/"

      sections.forEach(section => {
        const sectionTop = section.offsetTop
        const sectionHeight = section.clientHeight
        if (window.pageYOffset >= sectionTop - sectionHeight / 3) {
          currentSection = section.getAttribute("id")
        }
      })

      setActiveSection(currentSection)
    }

    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <>
      <NavigationContainer sticky={isMobile ? 1 : 0}>
        {isOpen && <PreventBodyScroll />}
        <NavigationContainerInner sticky={!isMobile && isSticky ? 1 : 0}>
          <MenuContainer>
            <LogoContainer
              to="/"
              title="Startseite"
              onClick={() => setOpen(false)}
            >
              <Logo />
              <span>Ben App</span>
            </LogoContainer>

            {!isMobile && (
              <ListContainer>
                {navigationItems.map((item, idx) => (
                  <ListItem key={idx}>
                    <AnchorLink
                      to={item.path}
                      title={item.text}
                      className={
                        activeSection === item.path.replace("/#", "")
                          ? "active"
                          : ""
                      }
                    >
                      <span>{item.text}</span>
                    </AnchorLink>
                  </ListItem>
                ))}
              </ListContainer>
            )}
          </MenuContainer>
          <Button
            title={isMobile ? "Early-Access" : "Early-Access anfragen"}
            variant="primary"
            action={e => {
              setOpen(false)
              // setOpenContact(true)
              // setFromElement(from)
              // from.current = e?.target as HTMLElement
              navigate("/#Informiert_bleiben")
            }}
          />
        </NavigationContainerInner>
      </NavigationContainer>
    </>
  )
}

export const LogoContainer = styled(FadeLink)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  z-index: 3;

  span {
    font-family: ${({ theme }) => theme.fonts.semiBold};
    font-size: 1.25rem;
    ${bp("mobile")`font-size: 1rem;`}
  }

  svg {
    width: 2.5rem;
    height: 2.5rem;
  }
`

const ListItem = styled.li`
  display: flex;

  a {
    span {
      font-family: ${({ theme }) => theme.fonts.regular};
      position: relative;    
      height: 100%;
      display: flex;
      align-items: center;

      &:after {
        background: ${({ theme }) => theme.palette.primary.main};
        content: "";
        width: 0%;
        height: 0.25rem;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        transition: ${themeOptions.transition};
      }
    }

  }
  a:hover,
  .active {
    span {
      &:after {
        width: 100%;
      }
  }
`

const NavigationContainer = styled.header<{ sticky: number }>`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 100%;
  background: ${({ theme }) => theme.palette.background.default};
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 10px -5px;

  .progress-bar {
    position: fixed;
    bottom: -0.2rem;
    left: 0;
    right: 0;
    height: 0.2rem;
    background: ${({ theme }) => theme.palette.secondary.main};
    transform-origin: 0%;
    border-radius: 4px;
  }

  ${({ sticky }) =>
    !sticky &&
    `
      position: sticky;
      top: 0;
      z-index: 2;
      backdrop-filter: brightness(150%) saturate(100%) blur(0.5rem);
      -webkit-backdrop-filter: brightness(150%) saturate(100%) blur(0.5rem);
  `}
`

const NavigationContainerInner = styled(Container)<{ sticky: number }>`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  transition: ${themeOptions.transition};
  position: inherit;
  height: ${({ sticky }) =>
    sticky === 1 ? themeOptions.stickyHeaderHeight : themeOptions.headerHeight};
  }
`

const MenuContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3rem;
  height: 100%;
`

const ListContainer = styled.ul`
  display: flex;
  height: 100%;
  gap: 2rem;
`

export default Navigation
