import { createGlobalStyle, css } from "styled-components"

export type Pallette = keyof typeof palette

export const palette = {
  light: {
    primary: {
      main: "#FF4D00",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#000000",
      contrastText: "#FFFFFF",
    },
    error: {
      main: "#e11010",
    },
    background: {
      default: "#FFFFFF",
      paper: "#F2F2F2",
    },
    text: {
      main: "#000000",
      light: "#666666",
      border: "#D9D9D9",
    },
  },

  dark: {
    primary: {
      main: "#FF4D00",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#FFFFFF",
      contrastText: "#000000",
    },
    error: {
      main: "#e11010",
    },
    background: {
      default: "#000000",
      paper: "#121212",
    },
    text: {
      main: "#ffffff",
      light: "#888888",
      border: "#414141",
    },
  },
}

export const fonts = {
  light: `"Inter-Light", sans-serif`,
  medium: `"Inter-Medium", sans-serif`,
  regular: `"Inter-Regular", sans-serif`,
  bold: `"Inter-Bold", sans-serif`,
  semiBold: `"Inter-SemiBold", sans-serif`,
  extraBold: `"Inter-ExtraBold", sans-serif`,
}

export const themeOptions = {
  pageWidth: "76rem",
  slideWidth: "62rem",
  headerHeight: "5.5rem",
  stickyHeaderHeight: "3.5rem",
  distance: "1.5rem",
  footerDistance: "1rem 1.5rem",
  sectionDistance: "6rem 0",
  radius: "0.6rem",
  transition: "all 0.2s ease-out",
}

export const devicesSizing = {
  xl: 2000,
  lg: 1536,
  res: 1275,
  md: 1024,
  sm: 970,
  xs: 320,
}

export const breakpoints = {
  hdpi: `screen and (min-width: ${devicesSizing.xl}px)`,
  mdpi: `screen and (max-width: ${devicesSizing.lg}px)`,
  res: `screen and (max-width: ${devicesSizing.res}px)`,
  mobile: `screen and (max-width: ${devicesSizing.md}px)`,
  mobile_mdpi: `screen and (max-width: ${devicesSizing.sm}px)`,
  mobile_small: `screen and (max-width: ${720}px)`,
  mobile_ldpi: `screen and (max-width: ${devicesSizing.xs}px)`,
}

type Breakpoint = keyof typeof breakpoints

export const bp =
  (point: Breakpoint) =>
  (...args: any) =>
    css`
      @media ${breakpoints[point]} {
        ${(css as any)(...args)}
      }
    `

const Styles = createGlobalStyle`
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup,tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    word-break: break-word;
    font-size: 1rem;
    line-height: 1.618;
  }

  article, aside, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  ol, ul {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }


  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  *, *:after, *:before {
    box-sizing: border-box;
  }

  body, html {
    width: 100%;
    min-height: 100vh;
    font-family: ${({ theme }) => theme.fonts.regular};
  }

  button, input, textarea {
    outline: none;
    border: none;
    background: none;
    background-image: none;
    background-color: transparent;
    box-shadow: none;
    resize: none;
  }

  html {
    scroll-padding: ${themeOptions.stickyHeaderHeight};
    ${bp("mobile_mdpi")`scroll-padding:0;`};
  }

  html, body, * {
    color: ${({ theme }) => theme.palette.text.main};
    font-weight: 300;
  }

  .main {
    min-height: 70vh;
    overflow: hidden;
  }

  a {
    text-decoration: none;
    color: unset;
    transition: ${themeOptions.transition};
    -webkit-tap-highlight-color: transparent;
    width: fit-content;
    color: ${({ theme }) => theme.palette.text.main};
  }
  
  html, body {
    background: ${({ theme }) => theme.palette.background.default};
    font-size: 16px;

    scrollbar-width: none;
    -ms-overflow-style: none;

    ::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }

  h1 {
    font-size: 2.8rem;
    font-family: ${({ theme }) => theme.fonts.semiBold};
    line-height: 1.3;
  }
  
  h2 {
    font-size: 2rem;
    font-family: ${({ theme }) => theme.fonts.medium};
    line-height: 1.3;
  }
  
  h3 {
    font-size: 1.8rem;
    font-family: ${({ theme }) => theme.fonts.regular};
    line-height: 1.3;
  }

  strong {
    font-family: ${({ theme }) => theme.fonts.semiBold};
  }

  a:hover {
    color: ${({ theme }) => theme.palette.primary.main};
  }

  p {
    color: ${({ theme }) => theme.palette.text.light};
    white-space: pre-line;
  }
  
  sup {
    color: ${({ theme }) => theme.palette.text.light};
    vertical-align: super;
    font-size: x-small;
    margin-left: 0.1rem;
  }

  hr {
    height: 1px;
    width: 100%;
    background:${({ theme }) => theme.palette.text.border};
    border: none;
  }

  .Toastify__toast-body > div:last-child {
    font-size: 1rem;
    font-family: ${({ theme }) => theme.fonts.medium};
  }

  .marquee {
    display: flex;
    align-items: center;
    cursor: -webkit-grab;
  }
  
  .item {
    font-size: 1rem;
    letter-spacing: -0.05em;
    padding-right: 0.25em;
    white-space: nowrap;
    -webkit-user-select: none;
    user-select: none;
  }
  
  .drag {
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }
  
  .bg {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #e6d5b8;
  }
  
`

export default Styles
