import React from "react"
import styled, { css } from "styled-components"

interface CheckboxProps
  extends Omit<React.HTMLProps<HTMLLabelElement>, "onChange"> {
  onChange?: (c: boolean) => void
  defaultChecked?: boolean
  error?: boolean
}

const Checkbox: React.FC<CheckboxProps> = ({
  style,
  className,
  children,
  onChange,
  defaultChecked,
  checked,
  disabled,
  error = false,
}) => {
  return (
    <CheckboxContainer {...{ style, className, disabled, error }}>
      {children}

      <input
        type="checkbox"
        onChange={e => onChange?.(e.target.checked)}
        {...{ checked, defaultChecked, disabled }}
      />

      <span className="checkmark" />
    </CheckboxContainer>
  )
}

const CheckboxContainer = styled.label<{
  disabled?: boolean
  error: boolean
}>`
  position: relative;
  padding-left: 1.5rem;
  font-size: 0.875rem;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  color: ${({ theme, error }) =>
    error ? theme.palette.error.main : theme.palette.text.light};
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;

      filter: brightness(60%);
    `}

  a {
    color: ${({ theme }) => theme.palette.primary.main};
    text-decoration: underline;

    :hover {
      filter: brightness(85%);
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0.1rem;
    left: 0;
    height: 1.2rem;
    width: 1.2rem;
    border: 2px solid
      ${({ theme, error }) =>
        error ? theme.palette.error.main : theme.palette.text.main};
    border-radius: 3px;
    transition: all 0.1s linear;
    transition-property: background-color border-color;
  }

  input:checked ~ .checkmark {
    background: ${({ theme }) => theme.palette.text.main};
    filter: brightness(85%);

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0.3rem;
      width: 0.35rem;
      height: 12px;
      border: solid ${({ theme }) => theme.palette.background.default};
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }

  a:hover ~ .checkmark {
    background: ${({ theme }) => theme.palette.background.default} !important;
  }

  :hover input ~ .checkmark {
    filter: brightness(85%);
    background: ${({ theme }) => theme.palette.text.main};
  }

  :hover input:checked ~ .checkmark {
    background: ${({ theme }) => theme.palette.text.main};
    filter: brightness(85%);
  }
`

export default Checkbox
