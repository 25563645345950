import React, { RefObject, useEffect, useMemo, useRef, useState } from "react"
import { PageProps } from "gatsby"
import { createGlobalStyle, ThemeProvider } from "styled-components"
import Styles, { palette, fonts, Pallette } from "../theme/Styles"
import Navigation from "../components/Navigation"
import Footer from "../components/Footer"
import { motion, useScroll } from "framer-motion"
import ContactForm, {
  ContactFormContext,
} from "../components/modals/ContactForm"
import Modal from "../components/modals/Modal"
import { ToastContainer } from "react-toastify"
import { ParallaxProvider } from "react-scroll-parallax"
import CookieSetting, {
  CookieSettingContext,
} from "../components/modals/CookieSetting"
import Cookies from "js-cookie"
import "../theme/fonts/fonts.css"
import { isBrowser } from "../utils/utils"
import BenAppStamp from "../components/BenAppStamp"
import CookiesBanner from "../components/CookiesBanner"
import useBreakpoint from "../utils/use-breakpoint"

const Page: React.FC<PageProps> = ({ children, location }) => {
  const isMobile = useBreakpoint()
  const [fromElement, setFromElement] = useState<React.RefObject<HTMLElement>>()
  const [openContact, setOpenContact] = useState(false)
  const [fromElementCookies, setFromElementCookies] =
    useState<React.RefObject<HTMLElement>>()
  const [openCookies, setOpenCookies] = useState(false)
  const [sendContact, setSetContact] = useState(false)
  const [analytics, setAnalytics] = useState(true)
  const ref = useRef(null)

  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start start", "end end"],
  })

  const prefersDark =
    isBrowser &&
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches

  const [mode, setMode] = useState<Pallette>("light")

  const acceptCookies = () => {
    // Set a cookie to remember that the user has accepted cookies
    Cookies.set("accept_cookies", "true", { expires: 30 }) // Cookie expires in 1 month
    // analytics && localStorage.setItem(GTAG_OPTIN_KEY, true as any)
    setOpenCookies(false)
  }

  useEffect(() => {
    if (prefersDark) {
      setMode("dark")
    }
  }, [prefersDark])

  const theme = useMemo(() => {
    return { palette: palette["light"], fonts }
  }, [mode])

  const noHeader =
    isBrowser && window.location.pathname === "/datenschutz-consumer-app/"

  return (
    <ThemeProvider theme={theme}>
      <ParallaxProvider>
        <CookieSettingContext.Provider
          value={{
            openCookies,
            setOpenCookies,
            fromElementCookies,
            setFromElementCookies,
          }}
        >
          <ContactFormContext.Provider
            value={{ openContact, setOpenContact, fromElement, setFromElement }}
          >
            <Styles />
            {!noHeader && <Navigation />}

            <motion.div
              className="main"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
            >
              {children}
            </motion.div>

            <BenAppStamp {...{ scrollYProgress }} />

            {!noHeader && <Footer />}

            <Modal
              showBackdrop
              isShown={openContact}
              fromElement={
                fromElement as RefObject<HTMLElement | null> | undefined
              }
              onClose={() => setOpenContact(false)}
              title="Kontaktformular"
              acceptButton={isMobile ? "Senden" : "Anfrage absenden"}
              showCloseButton
              onAccepted={() => setSetContact(true)}
            >
              <ContactForm
                onSend={() => setSetContact(false)}
                sendContact={sendContact}
              />
            </Modal>

            <Modal
              showBackdrop
              isShown={openCookies}
              fromElement={
                fromElement as RefObject<HTMLElement | null> | undefined
              }
              onClose={() => setOpenCookies(false)}
              title="Cookie Einstellungen"
              disableOutsideClick
              showCloseButton={false}
              onAccepted={acceptCookies}
              acceptButton="Cookies akzeptieren"
            >
              <CookieSetting onAnalytics={e => setAnalytics(e)} dark />
            </Modal>

            <CookiesBanner onSetting={() => setOpenCookies(true)} />

            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover={false}
              theme="light"
            />
          </ContactFormContext.Provider>
        </CookieSettingContext.Provider>
      </ParallaxProvider>
    </ThemeProvider>
  )
}

function Item() {
  const ref = useRef(null)
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["end end", "start start"],
  })

  return (
    <section id="prog">
      <div ref={ref}>
        <figure className="progress">
          <svg id="progress" width="75" height="75" viewBox="0 0 100 100">
            <circle cx="50" cy="50" r="30" pathLength="1" className="bg" />
            <motion.circle
              cx="50"
              cy="50"
              r="30"
              pathLength="1"
              className="indicator"
              style={{ pathLength: scrollYProgress }}
            />
          </svg>
        </figure>
      </div>
    </section>
  )
}

export const PreventBodyScroll = createGlobalStyle<{ isAnimating?: boolean }>`
   html {
    overflow: hidden;
    
    ${props => props.isAnimating && `pointer-events: none;`}
  }

`

export default Page
