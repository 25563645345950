import React, { createContext, RefObject, useEffect, useState } from "react"
import styled from "styled-components"
import { bp } from "../../theme/Styles"
import TextField from "../layouts/TextField"
import TextArea from "../layouts/TextArea"
import { useNotify } from "../../utils/utils"
import "react-toastify/dist/ReactToastify.css"
import Checkbox from "../layouts/Checkbox"
import useBreakpoint from "../../utils/use-breakpoint"

const ContactForm: React.FC<{ onSend: () => void; sendContact: boolean }> = ({
  onSend,
  sendContact,
}) => {
  const toast = useNotify()
  const isMobile = useBreakpoint()
  const [fullName, setFullName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [isError, setIsError] = useState(false)
  const [privacy, setPrivacy] = useState(false)

  const send = async () => {
    if (!fullName || !email || !privacy) {
      setIsError(true)
      onSend()
      return
    }

    // Perform API call to send form data
    try {
      const response = await fetch("https://mailer.ben.app/contacts", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: fullName,
          email,
          message,
        }),
      })

      // Handle the response as needed
      if (response.status === 400) {
        toast.error("Bitte geben Sie eine gültige E-Mail Adresse ein")

        setIsError(true)
        onSend()
      } else if (response.ok) {
        toast.success("E-Mail erfolgreich gesendet")

        setFullName("")
        setEmail("")
        setMessage("")
        setIsError(false)
        onSend()
      } else {
        toast.error(
          "Ein Fehler ist aufgetreten. Bitte überprüfen Sie Ihre Eingaben."
        )
        setIsError(true)
      }
    } catch (error) {
      setIsError(true)
      console.error("Error occurred:", error)
      onSend()
    }
  }

  useEffect(() => {
    if (sendContact) send()
  }, [sendContact])

  return (
    <CFContainer>
      {!isMobile && (
        <>
          <Text>
            <p>
              Vereinbaren Sie jetzt einen persönlichen Beratungstermin, indem
              Sie das nachfolgende Formular mit Ihren Daten ausfüllen. Unser
              Team wird sich umgehend bei Ihnen melden.
            </p>
            <strong>Wir freuen uns darauf, von Ihnen zu hören!</strong>
          </Text>
          <hr />
        </>
      )}

      <ContactFormContainer>
        <h3>So erreichen Sie uns!</h3>
        <div className="fields">
          <TextField
            value={fullName}
            name="Name"
            placeHolder="Vollständigen Namen eingeben"
            onValueChange={v => setFullName(v)}
            isError={isError && !fullName}
            errorText="Bitte geben Sie Ihren Namen an"
          />
          <TextField
            value={email}
            name="E-Mail Adresse"
            placeHolder="E-Mail Adresse eingeben"
            onValueChange={v => setEmail(v)}
            isError={isError && !email}
            errorText="Bitte geben Sie Ihre E-Mail Adresse an"
          />
        </div>

        <TextArea
          name="Nachricht"
          placeHolder="Schreiben Sie uns eine Nachricht"
          value={message}
          onValueChange={v => setMessage(v)}
        />
      </ContactFormContainer>

      <hr />

      <CheckboxContainer>
        <h4>Einverständniserklärungen</h4>
        <Checkbox onChange={e => setPrivacy(e)} error={isError && !privacy}>
          {!isMobile ? (
            <>
              Datenschutzhinweis: Die Garage 51 GmbH verwendet Ihre Daten nur
              zum Zwecke der Beantwortung Ihrer Anfrage, oder Weiterleitung
              Ihrer Anfrage an den zuständigen Vertriebspartner, bzw. auf Ihren
              Wunsch für die Zusendung eines Newsletters. Generelle Hinweise zum
              Datenschutz und zu Ihren Rechten als Betroffener finden Sie{" "}
              <a target="_blank" href="/datenschutz">
                hier
              </a>
              .
            </>
          ) : (
            <>
              Ich akzeptiere die{" "}
              <a target="_blank" href="/datenschutz">
                Datenschutz
              </a>
            </>
          )}
        </Checkbox>
      </CheckboxContainer>
    </CFContainer>
  )
}

export const ContactFormContext = createContext<{
  openContact: boolean
  setOpenContact: (v: boolean) => void
  fromElement: React.RefObject<HTMLElement> | undefined
  setFromElement: (v?: React.RefObject<HTMLElement> | undefined) => void
}>({
  openContact: false,
  setOpenContact: (v: boolean) => {},
  fromElement: undefined,
  setFromElement: (v?: RefObject<HTMLElement>) => {},
})

const CFContainer = styled.div`
  padding: 1.5rem;
  h2,
  h3 {
    color: ${({ theme }) => theme.palette.text.main};
  }

  h3 {
    font-size: 1.125rem;
  }

  hr {
    margin: 1rem 0;
  }

  ${bp("mobile_mdpi")`padding: 2rem 1rem;`}
`

const ContactFormContainer = styled.div`
  display: flex;
  flex-direction: column;

  .fields {
    padding-top: 1rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
    ${bp("mobile_mdpi")`grid-template-columns: repeat(1, 1fr);`}
    margin-bottom: 1rem;
  }

  .button {
    align-self: flex-end;
  }
`

const Text = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const CheckboxContainer = styled.div`
  background: ${({ theme }) => theme.palette.background.paper};
  border-radius: 1rem;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  h4 {
    font-size: 1rem;
    font-family: ${({ theme }) => theme.fonts.bold};
  }
`

export default ContactForm
