import React, { createContext, RefObject, useState } from "react"
import styled from "styled-components"
import { bp } from "../../theme/Styles"
import useBreakpoint from "../../utils/use-breakpoint"
import Button from "../layouts/Button"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Checkbox from "../layouts/Checkbox"

const CookieSetting: React.FC<{
  dark?: boolean
  onAnalytics: (e: boolean) => void
}> = ({ dark = false, onAnalytics }) => {
  const isMobile = useBreakpoint()

  const [fullName, setFullName] = useState("")
  const [department, setDepartment] = useState("")
  const [email, setEmail] = useState("")
  const [telefon, setTelefon] = useState("")
  const [institution, setInstitution] = useState("")
  const [plz, setPlz] = useState("")
  const [isError, setIsError] = useState(false)

  const send = async (e: React.FormEvent) => {
    e.preventDefault()

    const error =
      !fullName || !email || !department || !telefon || !institution || !plz

    if (error) {
      setIsError(true)
      return
    }

    // Perform API call to send form data
    try {
      const response = await fetch("https://api.garage51.de/send", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          fullName,
          email,
          department,
          telefon,
          institution,
          plz,
        }),
      })

      // Handle the response as needed
      if (response.ok) {
        toast.success("E-Mail erfolgreich gesendet", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        })

        setFullName("")
        setEmail("")
        setTelefon("")
        setDepartment("")
        setInstitution("")
        setPlz("")
        setIsError(false)
      } else {
        // Error handling
        toast.error(
          "Ein Fehler ist aufgetreten. Bitte überprüfen Sie Ihre Eingaben.",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        )
        setIsError(true)
      }
    } catch (error) {
      setIsError(true)
      console.error("Error occurred:", error)
    }
  }

  return (
    <CFContainer dark={dark ? 1 : 0}>
      <CookieSettingHead>
        <p>
          Wir verwenden Cookies zur automatisierten Datenerfassung. Einige
          dieser Cookies sind für den Betrieb notwendig und können nicht
          deaktiviert werden. Andere nicht notwendige Cookies helfen uns, den
          Erfolg unserer Marketingaktivitäten zu messen, die Nutzung zu
          analysieren und dieses für Sie noch passgenauer zu gestalten.
        </p>
      </CookieSettingHead>

      <CookieSettingContent>
        <Checkbox defaultChecked={true} disabled={true}>
          Notwendigen Cookies
        </Checkbox>
        <Checkbox defaultChecked={true} onChange={e => onAnalytics(e)}>
          Google Analytics
        </Checkbox>
      </CookieSettingContent>
    </CFContainer>
  )
}

export const CookieSettingContext = createContext<{
  openCookies: boolean
  setOpenCookies: (v: boolean) => void
  fromElementCookies: React.RefObject<HTMLElement> | undefined
  setFromElementCookies: (v?: React.RefObject<HTMLElement> | undefined) => void
}>({
  openCookies: false,
  setOpenCookies: (v: boolean) => {},
  fromElementCookies: undefined,
  setFromElementCookies: (v?: RefObject<HTMLElement>) => {},
})

const CFContainer = styled.div<{ dark?: number }>`
  ${({ theme, dark }) =>
    dark &&
    `
      padding: 1.5rem 1.5rem;

      h2,h3 {
        color: ${theme.palette.text.main};
      }

      h3 {
        font-size: 1.125rem;
      }
  `}

  hr {
    margin: 1rem 0;
  }

  ${bp("mobile_mdpi")`padding: 2rem 1rem;`}
`

const CookieSettingHead = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  h2 {
    font-size: 2rem;
    ${bp("mobile_mdpi")`font-size: 1.5rem;`}
  }
`

const CookieSettingContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 2rem;
`

const CookieSettingAction = styled.div`
  display: flex;
  padding-top: 2rem;
  justify-content: flex-end;
`

export default CookieSetting
