import React, { useContext, useRef } from "react"
import styled from "styled-components"
import { Container } from "./layouts/Container"
import { LogoContainer } from "./Navigation"
import Logo from "../assets/icons/Logo.svg"
import Linkedin from "../assets/icons/Icn_Linkedin.svg"
import Youtube from "../assets/icons/Icn_Youtube.svg"
import X from "../assets/icons/Icn_X.svg"
import Instagram from "../assets/icons/Icn_Instagram.svg"
import { bp, themeOptions } from "../theme/Styles"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import FadeLink from "./layouts/FadeLink"
import { CookieSettingContext } from "./modals/CookieSetting"

const followUs = [
  {
    link: "https://www.youtube.com/@Ben-app",
    name: "Youtube",
    icon: <Youtube />,
    color: "#ff0001",
  },
  {
    link: "https://x.com/Benapp_de",
    name: "X",
    icon: <X />,
    color: "#ffffff",
  },
  {
    link: "https://www.instagram.com/garage51.de",
    name: "Instagram",
    icon: <Instagram />,
    color: "#db2c7b",
  },
  {
    link: "https://www.linkedin.com/company/ben-app-revolution",
    name: "LinkedIn",
    icon: <Linkedin />,
    color: "#0966c2",
  },
]

export const copyRightLinks = [
  { link: "/impressum", name: "Impressum" },
  { link: "/datenschutz", name: "Datenschutz" },
]

export const legalLinks = [
  { path: "##", text: "Cookies" },
  { path: "/", text: "Terms and Conditions" },
  { path: "/datenschutz", text: "Datenschutz" },
  { path: "/impressum", text: "Impressum" },
]

const Footer = () => {
  const { setOpenCookies, setFromElementCookies } =
    useContext(CookieSettingContext)

  const from = useRef<HTMLElement | null>(null)

  return (
    <FooterContainer>
      <ContactContainer>
        <LogoContainer to="/" title="Startseite">
          <Logo />
          <span>Ben App</span>
        </LogoContainer>
      </ContactContainer>

      <LinksContainer>
        <div className="copy">
          <p>
            Die Ben App revolutioniert die Terminverwaltung in
            Dienstleistungsunternehmen und bietet sowohl für Kunden als auch für
            Dienstleister zahlreiche Vorteile.
            <br />
            <br />
            Seien Sie Teil der Revolution!
          </p>
        </div>

        <Navigation>
          <h3>Legal</h3>
          <ul>
            {legalLinks.map((item, idx) => (
              <li key={idx}>
                {item.path === "##" ? (
                  <FadeLink
                    to="#"
                    onClick={e => {
                      setOpenCookies(true)
                      setFromElementCookies(from)
                      from.current = (
                        e as React.MouseEvent<HTMLSpanElement, MouseEvent>
                      ).target as HTMLElement
                    }}
                    title={item.text}
                  >
                    {item.text}
                  </FadeLink>
                ) : (
                  <FadeLink to={item.path} title={item.text}>
                    {item.text}
                  </FadeLink>
                )}
              </li>
            ))}
          </ul>
        </Navigation>
      </LinksContainer>

      <CopyRightContainer>
        <p>© {new Date().getFullYear()} by Ben App. All rights reserved.</p>
        <div>
          {followUs.map((item, idx) => (
            <FolowLink
              key={idx}
              href={item.link}
              title={`Folge uns auf ${item.name}`}
              target="_blank"
              rel="nofollow"
              color={item.color}
            >
              {item.icon}
            </FolowLink>
          ))}
        </div>
      </CopyRightContainer>
    </FooterContainer>
  )
}

const FooterContainer = styled.footer`
  width: 100%;
  background-color: ${({ theme }) => theme.palette.text.main};
  color: ${({ theme }) => theme.palette.text.light};
  padding: 4rem 0;
`

const ContactContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  padding: ${themeOptions.footerDistance};
  gap: 2rem;
  align-items: center;

  p {
    color: ${({ theme }) => theme.palette.text.light};
    font-size: 2rem;
    ${bp("mobile")`font-size: 1.5rem;`};
  }

  span {
    color: ${({ theme }) => theme.palette.background.default};
  }
`

const CopyRightContainer = styled(Container)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: ${themeOptions.footerDistance};
  gap: 2rem;

  ${bp("mobile")`display:flex;flex-direction: column-reverse;gap: 1rem;`}

  p,
  a,
  span {
    color: ${({ theme, color }) => color ?? theme.palette.text.light};
  }
  p {
    grid-column: 1 / 4;
  }

  a:hover {
    color: ${({ theme }) => theme.palette.primary.main};
  }

  div {
    display: flex;
    gap: 1.5rem;
    align-items: center;
  }
`

const FolowLink = styled(OutboundLink)<{ color: string }>`
  svg {
    width: 1.5rem;
    height: 1.5rem;

    path {
      transition: ${themeOptions.transition};
      fill: ${({ theme }) => theme.palette.text.light};
    }

    &:hover path {
      fill: ${({ theme, color }) => color ?? theme.palette.text.light};
    }
  }
`

const LinksContainer = styled(Container)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: space-between;
  padding: ${themeOptions.footerDistance};
  gap: 2rem;

  .copy {
    grid-column: 1 / 4;

    p {
      max-width: 27rem;
    }
  }

  ${bp("mobile")`grid-template-columns: repeat(1, 1fr);`}

  h3 {
    color: ${({ theme }) => theme.palette.text.light};
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  li {
    color: ${({ theme }) => theme.palette.text.light};
    margin-bottom: 0.5rem;

    span {
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }

  p {
    color: ${({ theme }) => theme.palette.text.light};
    margin-bottom: 0.5rem;
  }
`

const Navigation = styled.div`
  li {
    margin-bottom: 0.5rem;

    a {
      color: ${({ theme }) => theme.palette.background.default};
      opacity: 0.8;
    }
  }
`

export default Footer
