import React from "react"
import TransitionLink from "gatsby-plugin-transition-link"
import anime from "animejs"

const FadeLink: React.FC<{
  to?: string
  title?: string
  className?: string
  onClick?: (
    e: Event | React.FormEvent | React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => void
  children: any
}> = ({ to, children, className, onClick, title }) => {
  return (
    <TransitionLink
      exit={{
        length: 0.3,
        trigger: ({ node }: { node: any }) =>
          anime({
            targets: [node],
            duration: 0,
            opacity: [1, 0],
            easing: "linear",
          }),
      }}
      entry={{
        length: 0.3,
        trigger: ({ node }: { node: any }) =>
          anime({
            targets: [node],
            duration: 400,
            opacity: [0, 1],
            easing: "linear",
          }),
      }}
      onClick={(e: any) => onClick?.(e)}
      className={className}
      to={to}
      title={title}
    >
      {children}
    </TransitionLink>
  )
}

export default FadeLink
