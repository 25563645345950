import { motion } from "framer-motion"
import React from "react"
import styled from "styled-components"
import { themeOptions } from "../../theme/Styles"
import FadeLink from "./FadeLink"

type ButtonVariant = "primary" | "secondary" | "outlined" | "text"

const Button: React.FC<{
  variant: ButtonVariant
  type?: "link" | "button"
  title: string
  action?: (e: Event | React.FormEvent) => void
  to?: string
  target?: React.HTMLAttributeAnchorTarget
}> = ({
  title,
  variant = "primary",
  action = () => {},
  type = "button",
  target,
  to,
}) => (
  <>
    {type === "button" && variant != "text" ? (
      <FadeLink to="" onClick={(e: any) => action(e)}>
        <ButtonContainer
          whileTap={{ scale: 0.95 }}
          {...{ variant }}
          className="button"
        >
          <span>{title}</span>
        </ButtonContainer>
      </FadeLink>
    ) : variant === "text" ? (
      <ButtonContainer
        whileTap={{ scale: 0.95 }}
        {...{ variant }}
        className="button"
        onClick={(e: any) => action(e)}
      >
        <span>{title}</span>
      </ButtonContainer>
    ) : (
      <a href={to ?? ""} title={title} target={target} rel="nofollow">
        <ButtonContainer
          whileTap={{ scale: 0.95 }}
          {...{ variant }}
          className="button"
        >
          <span>{title}</span>
        </ButtonContainer>
      </a>
    )}
  </>
)

export const ButtonContainer = styled(motion.div)<{ variant: ButtonVariant }>`
  display: flex;
  gap: 0.75rem;
  background: linear-gradient(#0000, rgb(0 0 0/15%)) top/100% 800%;
  background-color: ${({ theme, variant }) =>
    variant === "secondary"
      ? theme.palette.text.main
      : variant === "outlined"
      ? "transparent"
      : theme.palette.primary.main};
  align-items: center;
  padding: 0.65rem 1.5rem;
  border-radius: 6.25rem;
  cursor: pointer;
  transition: ${themeOptions.transition};
  user-select: none;
  width: fit-content;
  -webkit-tap-highlight-color: transparent;

  ${({ theme, variant }) =>
    variant === "outlined" && `border: 1px solid ${theme.palette.text.border}`};

  span {
    color: ${({ theme, variant }) =>
      variant === "secondary"
        ? theme.palette.secondary.contrastText
        : variant === "outlined"
        ? theme.palette.text.main
        : theme.palette.primary.contrastText};
    font-family: ${({ theme }) => theme.fonts.semiBold};
    font-size: 1rem;
  }

  svg {
    position: relative;
    width: 1.125rem;
    transition: ${themeOptions.transition};
    right: 0;
  }

  &:hover {
    background-position: bottom;
    svg {
      right: -0.3rem;
    }
  }

  ${({ variant, theme }) =>
    variant === "text" &&
    `
      background: transparent;
      background-color: transparent;
      padding: 0;
      span,path {
        color: ${theme.palette.primary.main};
        fill: ${theme.palette.primary.main};
      }
  `}
`
export default Button
