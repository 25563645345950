import React, { useId } from "react"
import styled from "styled-components"
import { themeOptions } from "../../theme/Styles"

type TextAreaProps = {
  name: string
  value: string
  placeHolder?: string
  onValueChange: (v: string) => void
}

const TextArea: React.FC<TextAreaProps> = ({
  name,
  value,
  placeHolder,
  onValueChange,
}) => {
  const id = useId()

  return (
    <TextAreaContainer>
      <label htmlFor="textarea">{name}</label>
      <textarea
        value={value}
        rows={3}
        id="textarea"
        placeholder={placeHolder ?? name}
        onChange={e => onValueChange(e.target.value)}
      />
    </TextAreaContainer>
  )
}

const TextAreaContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  position: relative;
  width: 100%;

  label {
    font-family: ${({ theme }) => theme.fonts.medium};
    color: ${({ theme }) => theme.palette.text.light};
    font-size: 0.875rem;
  }

  textarea {
    width: 100%;
    outline: 1px solid ${({ theme }) => theme.palette.text.border};
    color: ${({ theme }) => theme.palette.text.main};
    background: transparent;
    border-radius: 8px;
    font-family: ${({ theme }) => theme.fonts.regular};
    font-size: 0.78rem;
    padding: 0.7rem 0.9rem;
    border-bottom: 3px solid transparent;
    transition: ${themeOptions.transition};

    &:focus {
      border-color: ${({ theme }) => theme.palette.primary.main};
    }
  }
`

export default TextArea
