import React, { useState, useEffect } from "react"
import Cookies from "js-cookie"
import styled from "styled-components"
import { bp, themeOptions } from "../theme/Styles"
import { Container } from "./layouts/Container"
import Button from "./layouts/Button"

const CookiesBanner: React.FC<{ onSetting: () => void }> = ({ onSetting }) => {
  const [isBannerVisible, setIsBannerVisible] = useState(false)

  useEffect(() => {
    // Check if the user has accepted cookies by checking a cookie value
    const hasAcceptedCookies = Cookies.get("accept_cookies")
    if (!hasAcceptedCookies) {
      setIsBannerVisible(true)
    }
  }, [])

  const acceptCookies = () => {
    // Set a cookie to remember that the user has accepted cookies
    Cookies.set("accept_cookies", "true", { expires: 30 }) // Cookie expires in 1 month
    setIsBannerVisible(false)
  }

  return (
    <>
      <CookiesBannerContainer visible={isBannerVisible ? 1 : 0}>
        {isBannerVisible && (
          <Container>
            <CookiesBannerContainerInner>
              <div className="text">
                <p>
                  Wir nutzen Cookies von Google Analytics um unsere Seite weiter
                  für dich optimieren zu können.
                </p>
                <Button
                  title="Einstellungen"
                  variant="text"
                  action={() => {
                    setIsBannerVisible(false)
                    onSetting()
                  }}
                />
              </div>
              <Button
                title="Bestätigen und schließen"
                action={acceptCookies}
                variant="primary"
              />
            </CookiesBannerContainerInner>
          </Container>
        )}
      </CookiesBannerContainer>
    </>
  )
}

const CookiesBannerContainer = styled.div<{ visible: number }>`
  background-color: ${({ theme }) => theme.palette.background.default};
  transition: ${themeOptions.transition};
  position: fixed;
  bottom: 0;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  width: 100%;
  z-index: 1111;
`

const CookiesBannerContainerInner = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  padding: 2rem 0;

  .text {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }

  ${bp("mobile_mdpi")`
    flex-direction: column;
    
    .button {
      justify-content: center;
      width: 100%;
    }

    .text {
      flex-direction: column;

      .button {
        justify-content: left;
      }
    }
  `}

  p {
    font-size: 1rem;
    color: ${({ theme }) => theme.palette.text.main};
  }

  a {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`

export default CookiesBanner
